import type { NextPage } from 'next';
import { Router, persistor } from '../src/infrastructure';
import { PersistGate } from 'redux-persist/integration/react';
import { FeatureFlagItem, FeatureFlagHighlight } from '@maverick/entity';
import { FeatureFlag } from '../src/infrastructure/FeatureFlag';

export interface HomeProps {
	allowDelivery: boolean;
	featureFlagItems: Array<FeatureFlagItem>;
	featureHighlight: Array<FeatureFlagHighlight>;
}

const Home: NextPage<HomeProps> = ({ allowDelivery, featureFlagItems, featureHighlight }) => {
	return (
		<>
			<PersistGate loading={<>loading</>} persistor={persistor}>
				<FeatureFlag
					allowDeliveryItem={allowDelivery}
					items={featureFlagItems}
					highlightItems={featureHighlight}
				/>
				<Router />
			</PersistGate>
		</>
	);
};

export default Home;
