import { ConnectedProps, connect } from 'react-redux';
import { RootState } from '../Store';
import { FeatureFlag } from './FeatureFlag';
import { AppOperations } from '../../features/App/redux/App.operations';

const mapState = (state: RootState) => ({
	featureFlagItems: state.app.featureFlagItems,
	featureHighlight: state.app.featureHighlight,
	allowDelivery: state.app.allowDelivery,
});

const mapDispatch = {
	setFeatureFlagItems: AppOperations.setFeatureFlagItems,
	setFeatureHighlight: AppOperations.setFeatureHighlight,
	setAllowDelivery: AppOperations.setAllowDelivery,
};

const connector = connect(mapState, mapDispatch);
const FeatureFlagConnected = connector(FeatureFlag);

export type FeatureFlagReduxProps = ConnectedProps<typeof connector>;
export { FeatureFlagConnected as FeatureFlag };
