import { FeatureFlagItem, FeatureFlagHighlight, getFeatureFlagItem, getFeatureHighlight } from '@maverick/entity';
import { FC, useEffect } from 'react';
import { FeatureFlagReduxProps } from '.';
import { RandomInt } from '@maverick/utils';

export interface FeatureFlagProps extends FeatureFlagReduxProps {
	allowDeliveryItem: boolean;
	items: Array<FeatureFlagItem>;
	highlightItems: Array<FeatureFlagHighlight>;
}
export const FeatureFlag: FC<FeatureFlagProps> = ({
	items: incomingItems,
	highlightItems: incomingHighlightItems,
	allowDeliveryItem: allowDelivery,
	featureFlagItems: currentItems,
	featureHighlight: currentHighlightItems,
	setAllowDelivery,
	setFeatureFlagItems,
	setFeatureHighlight,
}) => {
	useEffect(() => {
		const itemsToSet = new Array<FeatureFlagItem>();

		incomingItems?.forEach((item) => {
			if (!item.live) return;

			const existingItem = getFeatureFlagItem(currentItems, item.key);
			if (existingItem) {
				itemsToSet.push(existingItem);
			} else {
				const randomNumber = RandomInt(0, 100);
				const active = randomNumber <= item.threshold;
				itemsToSet.push({ ...item, active });
			}
		});

		setFeatureFlagItems(itemsToSet);
	}, [incomingItems]);

	useEffect(() => {
		const itemsHighlightToSet = new Array<FeatureFlagHighlight>();

		incomingHighlightItems?.forEach((item) => {
			if (!item.live) return;

			const existingHighlightItem = getFeatureHighlight(currentHighlightItems, item.key);
			if (existingHighlightItem) {
				itemsHighlightToSet.push(existingHighlightItem);
			} else {
				const randomNumber = RandomInt(0, 100);
				const active = randomNumber <= item.threshold;
				itemsHighlightToSet.push({ ...item, active });
			}
		});

		setFeatureHighlight(itemsHighlightToSet);
	}, [incomingHighlightItems]);

	useEffect(() => {
		setAllowDelivery(allowDelivery);
	}, [allowDelivery]);

	return null;
};
